export enum ModelType {
  BASE = 'base',
  CUSTOM = 'custom',
  SYNTH = 'synth'
}

export interface Model {
  _id: string;
  baseModelId: string;
  name: string;
  description?: string;
  type: ModelType;

  baseProperties: {
    contextWindow: number;
    modality: {
      input: {
        text: boolean;
        audio: boolean;
        image: boolean;
        video: boolean;
      };
      output: {
        text: boolean;
        audio: boolean;
        image: boolean;
        video: boolean;
      };
    };
    tokenizer: string;
    inputTokenCost: string;
    outputTokenCost: string;
    isModerated: boolean;
  };

  customProperties: {
    temperature?: number;
    maxOutputTokens: number;
    topP?: number;
    topK?: number;
    frequencyPenalty?: number;
    presencePenalty?: number;
    repetitionPenalty?: number;
    promptCaching?: boolean;
  };

  userId?: string;
  organizationId?: string;
  systemPrompt?: string;
  knowledgeBaseIds?: string[];
  toolIds?: string[];
  thumbnail?: string;
  metadata?: Record<string, any>;
  tags?: string[];

  createdAt: string;
  updatedAt: string;
  isActive: boolean;
  isDeleted: boolean;
  isFavorite: boolean;
} 