import { Notification } from '../types/Notification';
import { axiosInstance } from './axiosConfig';

/**
 * Fetches notifications for the current user
 * @returns Array of notifications sorted by creation date
 * @throws {ServerError} For unexpected errors
 */
export const fetchNotifications = async (): Promise<Notification[]> => {
  try {
    const response = await axiosInstance.get('/notifications');
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Marks a specific notification as read
 * @param id - The ID of the notification to mark as read
 * @throws {NotFoundError} If notification doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const markNotificationAsRead = async (id: string): Promise<Notification> => {
  try {
    const response = await axiosInstance.put(`/notifications/${id}/read`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

/**
 * Marks all notifications as read for the current user
 * @throws {ServerError} For unexpected errors
 */
export const markAllNotificationsAsRead = async (): Promise<void> => {
  try {
    await axiosInstance.put('/notifications/read-all');
  } catch (error) {
    throw error;
  }
};

/**
 * Deletes a specific notification
 * @param id - The ID of the notification to delete
 * @throws {NotFoundError} If notification doesn't exist
 * @throws {ServerError} For unexpected errors
 */
export const deleteNotification = async (id: string): Promise<void> => {
  try {
    await axiosInstance.delete(`/notifications/${id}`);
  } catch (error) {
    throw error;
  }
};