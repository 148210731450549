import { io, Socket } from 'socket.io-client';
import { CONFIG } from '../config';


let socket: Socket | null = null;
let currentToken: string | null = null;

export type SocketEventCallbacks = {
  onConnect?: () => void;
  onDisconnect?: (reason: string) => void;
  onReconnectAttempt?: (attemptNumber: number) => void;
  onReconnectFailed?: () => void;
  onReconnect?: (attemptNumber: number) => void;
  onError?: (error: Error) => void;
  onModelConfigUpdate?: (data: any) => void;
  onNewNotification?: (notification: any) => void;
};

export const initializeSocket = (token: string, callbacks: SocketEventCallbacks = {}) => {
  if (!token) {
    console.warn('Attempted to initialize socket without a token');
    return null;
  }

  if (socket) {
    console.log('SocketService: Disconnecting existing socket');
    disconnectSocket();
  }

  //console.log('SocketService: Creating new socket connection...');

  socket = io(CONFIG.SOCKET_URL, {
    auth: { token },
    transports: ['websocket'],
    reconnection: true,
    reconnectionAttempts: CONFIG.SOCKET.RECONNECTION.ATTEMPTS,
    reconnectionDelay: CONFIG.SOCKET.RECONNECTION.DELAY,
    reconnectionDelayMax: CONFIG.SOCKET.RECONNECTION.DELAY_MAX,
    randomizationFactor: CONFIG.SOCKET.RECONNECTION.RANDOMIZATION_FACTOR,
    autoConnect: true,
    withCredentials: true,
    timeout: parseInt(CONFIG.SOCKET_IO_PING_TIMEOUT || '10000'),

    secure: true,
    rejectUnauthorized: false
  });

  currentToken = token;
  setupSocketEventListeners(callbacks);

  return socket;
};

const setupSocketEventListeners = (callbacks: SocketEventCallbacks) => {
  if (!socket) return;

  socket.on('connect', () => {
    //console.log('Connected to Socket.IO server:', socket?.id);
    callbacks.onConnect?.();
  });

  socket.on('disconnect', (reason) => {
    console.log('Disconnected from Socket.IO server. Reason:', reason);
    callbacks.onDisconnect?.(reason);
  });

  socket.on('connect_error', (error: any) => {
    // console.log('SocketService: Connection error details:', {
    //   message: error.message,
    //   type: error.type,
    //   description: error.description
    // });
    callbacks.onError?.(error);
  });

  socket.io.on('error', (error: Error) => {
    console.log('SocketService: Transport error:', error);
  });

  socket.io.on('reconnect_attempt', (attemptNumber) => {
    console.log(`Reconnection attempt #${attemptNumber}`);
    if (currentToken) {
      if (socket && typeof socket.auth === 'object') {
        (socket.auth as { token: string }).token = currentToken;
        console.log('Updated socket.auth.token for reconnection attempt');
      } else {
        console.warn('reconnection attempt: Socket.auth is not an object');
      }
    } else {
      console.warn('reconnection attempt: No current token');
    }

    callbacks.onReconnectAttempt?.(attemptNumber);
  });

  socket.io.on('reconnect', (attemptNumber) => {
    console.log(`Successfully reconnected on attempt #${attemptNumber}`);
    callbacks.onReconnect?.(attemptNumber);
  });

  socket.io.on('reconnect_failed', () => {
    console.error('Reconnection failed');
    callbacks.onReconnectFailed?.();
  });

  socket.on('model-config-update', (data: any) => {
    //console.log('socketService: Received model config update');
    callbacks.onModelConfigUpdate?.(data);
  });

  socket.on('new-notification', (notification: any) => {
    //console.log('socketService: Received new notification: ', notification);
    callbacks.onNewNotification?.(notification);
  });

  // Log ping/pong for monitoring
  socket.io.on('ping', () => {
    //console.log('Socket.IO ping received');
    socket!.emit('pong');
  });
};

// const handleTokenRefresh = async () => {
//   try {
//     console.log('SocketService: Refreshing token');
//     const { payload } = await getStore().dispatch(refreshToken()).unwrap();
//     currentToken = payload.accessToken;
//     console.log('SocketService: Token refreshed successfully');
    
//     if (socket) {
//       socket.auth = { token: currentToken };
//       if (!socket.connected) {
//         socket.connect();
//       }
//     }
//   } catch (err) {
//     console.error('Failed to refresh access token:', err);
//   }
// };

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
  currentToken = null;
  socket = null;
};

export const updateSocketToken = (newToken: string) => {
  if (socket) {
    socket.auth = { token: newToken };
    console.log('Socket token updated');
  } else {
    console.warn('Attempted to update token for non-existent socket');
  }
};

export const getSocket = (token: string, callbacks: SocketEventCallbacks = {}): Socket | null => {
  if (!token) {
    console.warn('Attempted to get socket without a token');
    return null;
  }

  if (!socket) {
    return initializeSocket(token, callbacks);
  } else if (currentToken !== token) {
    currentToken = token;
    updateSocketToken(token);
    console.log('Updated existing socket with new token');
  }
  return socket;
};