import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { setModels } from '../store/modelSlice';
import { Model } from '../types/Model';

export const useModelConfigUpdate = () => {
  const dispatch = useDispatch();

  const handleModelConfigUpdate = useCallback((data: Model[] ) => {
    console.log('useModelConfigUpdate: Received model config update');
    const serializedData = data.map((config: Model) => ({
      ...config,
      updatedAt: (config.updatedAt ? new Date(config.updatedAt).toISOString() : undefined) || '',
    }));
    dispatch(setModels(serializedData));
  }, [dispatch]);

  return handleModelConfigUpdate;
};