export * from './Message';
export * from './User';
export * from './Chat';
export * from './Model';
export * from './Notification';
export * from './Folder';
export * from './Attachment';

export const serializeDate = (date: Date | string | undefined): string | undefined => {
  if (date instanceof Date) {
    return date.toISOString();
  } else if (typeof date === 'string') {
    return new Date(date).toISOString();
  }
  return undefined;
};

export const deserializeDate = (dateString: string): Date => new Date(dateString); 