export enum ErrorCode {
  VALIDATION_ERROR = 'VALIDATION_ERROR',
  AUTHENTICATION_ERROR = 'AUTHENTICATION_ERROR',
  NOT_FOUND_ERROR = 'NOT_FOUND_ERROR',
  EXTERNAL_SERVICE_ERROR = 'EXTERNAL_SERVICE_ERROR',
  RATE_LIMIT_ERROR = 'RATE_LIMIT_ERROR',
  SERVER_ERROR = 'SERVER_ERROR',

  CREDIT_INSUFFICIENT = 'CREDIT_INSUFFICIENT',
  CREDIT_PARTIALLY_SUFFICIENT = 'CREDIT_PARTIALLY_SUFFICIENT',

  INVALID_MODEL = 'INVALID_MODEL',
  CHAT_PERMISSION_ERROR = 'CHAT_PERMISSION_ERROR',

  MESSAGE_TOO_LONG = 'MESSAGE_TOO_LONG',
  CONTEXT_LIMIT_EXCEEDED = 'CONTEXT_LIMIT_EXCEEDED',
  
  MODERATED_CONTENT_ERROR = 'MODERATED_CONTENT_ERROR',
  SERVICE_UNAVAILABLE_ERROR = 'SERVICE_UNAVAILABLE_ERROR',
  TIMEOUT_ERROR = 'TIMEOUT_ERROR',

  OPENROUTER_ACCOUNT_ERROR = 'OPENROUTER_ACCOUNT_ERROR',
  OPENROUTER_RATE_LIMIT_ERROR = 'OPENROUTER_RATE_LIMIT_ERROR',
  OPENROUTER_TIMEOUT_ERROR = 'OPENROUTER_TIMEOUT_ERROR',
  OPENROUTER_API_ERROR = 'OPENROUTER_API_ERROR',
  OPENROUTER_UPSTREAM_ERROR = 'OPENROUTER_UPSTREAM_ERROR',

  TITLE_GENERATION_ERROR = 'TITLE_GENERATION_ERROR',
  GENERATION_DETAILS_ERROR = 'GENERATION_DETAILS_ERROR',

  FILE_SIZE_ERROR = 'FILE_SIZE_ERROR',
  FILE_TYPE_ERROR = 'FILE_TYPE_ERROR',
  FILE_UPLOAD_ERROR = 'FILE_UPLOAD_ERROR',
  FILE_PARSING_ERROR = 'FILE_PARSING_ERROR',
  FILE_NETWORK_ERROR = 'FILE_NETWORK_ERROR',
}