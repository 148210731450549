import { createSlice, PayloadAction, createSelector } from '@reduxjs/toolkit';
import { Model, ModelType } from '../types/Model';
import { Message, MessageSenderType } from '../types/Message';

// Define preset model IDs
export interface PresetModel {
  presetId: string;
  modelId: string;
  name: string;
  modelName: string;
  description: string;
  baseModelId: string;
}

interface ModelState {
  models: Model[];
  selectedModelId: string;
  customModels: Model[];
  isCustomizing: boolean;
  customizationDraft?: Partial<Model>;
  showModelHub: boolean;
  presetModels: PresetModel[];
  favoriteModelIds: string[];
  recentlyUsedModelIds: string[];
}

const initialState: ModelState = {
  models: [],
  selectedModelId: '',
  customModels: [],
  isCustomizing: false,
  customizationDraft: undefined,
  showModelHub: false,
  presetModels: [],
  favoriteModelIds: [],
  recentlyUsedModelIds: [],
};

const modelSlice = createSlice({
  name: 'model',
  initialState,
  reducers: {
    setModels: (state, action: PayloadAction<Model[]>) => {
      state.models = action.payload;
      
      // Initialize presets if they don't exist yet
      if (state.presetModels.length === 0 && action.payload.length >= 3) {
        // Use the first 3 models as presets by default
        state.presetModels = [
          { 
            presetId: 'general', 
            modelId: action.payload[0]._id, 
            name: 'General', 
            modelName: action.payload[0].name,
            description: 'Balanced performance',
            baseModelId: action.payload[0].baseModelId
          },
          { 
            presetId: 'professional', 
            modelId: action.payload.length > 1 ? action.payload[1]._id : action.payload[0]._id, 
            name: 'Professional', 
            modelName: action.payload[1].name,
            description: 'Enhanced quality',
            baseModelId: action.payload[1].baseModelId
          },
          { 
            presetId: 'research', 
            modelId: action.payload.length > 2 ? action.payload[2]._id : action.payload[0]._id, 
            name: 'Research', 
            modelName: action.payload[2].name,
            description: 'Highest capability',
            baseModelId: action.payload[2].baseModelId
          },
        ];
      }
      
      // Initialize favorite models if they don't exist yet
      if (state.favoriteModelIds.length === 0) {
        // Use the next 8 models after presets as favorite models
        state.favoriteModelIds = action.payload
          .slice(3, 11)
          .map(model => model._id);
      }
    },
    setSelectedModel: (state, action: PayloadAction<string | { modelId: string, default?: boolean }>) => {
      const modelId = typeof action.payload === 'string' ? action.payload : action.payload.modelId;
      const isDefault = typeof action.payload === 'string' ? false : !!action.payload.default;
      
      const modelExists = state.models.some((model: Model) => model._id === modelId);
      
      if (modelExists) {
        state.selectedModelId = modelId;
        
        // Only update recently used if not a default selection
        if (!isDefault) {
          // Add to recently used models
          const recentIndex = state.recentlyUsedModelIds.indexOf(modelId);
          if (recentIndex !== -1) {
            // Remove from current position
            state.recentlyUsedModelIds.splice(recentIndex, 1);
          }
          // Add to front of array
          state.recentlyUsedModelIds.unshift(modelId);
          // Keep only the 5 most recent
          if (state.recentlyUsedModelIds.length > 5) {
            state.recentlyUsedModelIds = state.recentlyUsedModelIds.slice(0, 5);
          }
        }
      } else {
        state.selectedModelId = state.models[0]?._id || '';
      }
    },
    setSelectedModelFromMessages: (state, action: PayloadAction<Message[]>) => {
      if(action.payload.length === 0){
        state.selectedModelId = state.models[0]?._id || '';
      } else {
        const latestAssistantMessage = [...action.payload]
          .reverse()
        .find(message => message.senderType === MessageSenderType.ASSISTANT);
        if(latestAssistantMessage){
          const modelExists = state.models.some((model: Model) => model._id === latestAssistantMessage.toModelId);
          if (modelExists) {
            state.selectedModelId = latestAssistantMessage.toModelId;
          } else{
            state.selectedModelId = state.models[0]?._id || '';
          }

        } else {
          const latestUserMessage = [...action.payload]
            .reverse()
            .find(message => message.senderType === MessageSenderType.USER);
          if (latestUserMessage?.toModelId) {
            const modelExists = state.models.some((model: Model) => model._id === latestUserMessage.toModelId);
            if (modelExists) {
              state.selectedModelId = latestUserMessage.toModelId;
            } else{
              state.selectedModelId = state.models[0]?._id || '';
            }

          } else {
            state.selectedModelId = state.models[0]?._id || '';
          }
        }
     }
    },

    toggleFavorite: (state, action: PayloadAction<string>) => {
      const modelId = action.payload;
      const index = state.favoriteModelIds.indexOf(modelId);
      if (index === -1) {
        state.favoriteModelIds.push(modelId);
      } else {
        state.favoriteModelIds.splice(index, 1);
      }
    },
    addCustomModel: (state, action: PayloadAction<Model>) => {
      state.customModels.push(action.payload);
      state.models.push(action.payload);
    },
    updateCustomModel: (state, action: PayloadAction<Model>) => {
      const index = state.customModels.findIndex(m => m._id === action.payload._id);
      if (index !== -1) {
        state.customModels[index] = action.payload;
        const modelIndex = state.models.findIndex(m => m._id === action.payload._id);
        if (modelIndex !== -1) {
          state.models[modelIndex] = action.payload;
        }
      }
    },
    deleteCustomModel: (state, action: PayloadAction<string>) => {
      state.customModels = state.customModels.filter(m => m._id !== action.payload);
      state.models = state.models.filter(m => m._id !== action.payload);
      if (state.selectedModelId === action.payload) {
        state.selectedModelId = state.models[0]?._id || '';
      }
    },
    startCustomizing: (state, action: PayloadAction<string>) => {
      state.isCustomizing = true;
      const baseModel = state.models.find(m => m._id === action.payload);
      if (baseModel) {
        state.customizationDraft = {
          baseModelId: baseModel.baseModelId,
          name: `${baseModel.name} (Custom)`,
          type: ModelType.CUSTOM,
          isFavorite: true,
          customProperties: {
            temperature: 0.7,
            maxOutputTokens: baseModel.customProperties.maxOutputTokens,
          }
        };
      }
    },
    updateCustomizationDraft: (state, action: PayloadAction<Partial<Model>>) => {
      state.customizationDraft = {
        ...state.customizationDraft,
        ...action.payload
      };
    },
    cancelCustomizing: (state) => {
      state.isCustomizing = false;
      state.customizationDraft = undefined;
    },
    setShowModelHub: (state, action: PayloadAction<boolean>) => {
      state.showModelHub = action.payload;
    },
    clearModelState: () => initialState,
    setPresetModel: (state, action: PayloadAction<{ presetId: string; modelId: string }>) => {
      const { presetId, modelId } = action.payload;
      const presetIndex = state.presetModels.findIndex(p => p.presetId === presetId);
      
      if (presetIndex !== -1) {
        state.presetModels[presetIndex].modelId = modelId;
      }
    },
    setFavoriteModels: (state, action: PayloadAction<string[]>) => {
      if(action.payload.length > 0){
        state.favoriteModelIds = action.payload;
      } else {
        state.favoriteModelIds = [];
      }
    },
  },
});

export const {
  setModels,
  setSelectedModel,
  setSelectedModelFromMessages,
  toggleFavorite,
  addCustomModel,
  updateCustomModel,
  deleteCustomModel,
  startCustomizing,
  updateCustomizationDraft,
  cancelCustomizing,
  clearModelState,
  setShowModelHub,
  setPresetModel,
  setFavoriteModels,
} = modelSlice.actions;

// Selectors
export const selectAllModels = (state: { model: ModelState }) => state.model.models;
export const selectFavoriteModels = createSelector(
  [selectAllModels, (state: { model: ModelState }) => state.model.favoriteModelIds],
  (models, favoriteIds) => models.filter((model: Model) => favoriteIds.includes(model._id))
);
export const selectCustomModels = (state: { model: ModelState }) => state.model.customModels;
export const selectIsCustomizing = (state: { model: ModelState }) => state.model.isCustomizing;
export const selectCustomizationDraft = (state: { model: ModelState }) => state.model.customizationDraft;

export const selectModelIds = createSelector(
  [(state) => state.model.models],
  (models: Model[]) => models.map(model => model._id)
);

export const selectPresetModels = createSelector(
  [(state) => state.model.models, (state) => state.model.presetModels],
  (models, presets) => {
    return presets.map((preset: PresetModel) => {
      const model = models.find((m: Model) => m._id === preset.modelId);
      return {
        ...preset,
        model
      };
    });
  }
);

export const selectRecentlyUsedModels = createSelector(
  [(state) => state.model.models, (state) => state.model.recentlyUsedModelIds],
  (models, recentIds) => {
    return recentIds
      .map((id: string) => models.find((m: Model) => m._id === id))
      .filter((model: Model | undefined) => model !== undefined) as Model[];
  }
);

export default modelSlice.reducer;