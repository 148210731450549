import { ErrorMapping, ErrorSeverity } from './error';
import { ErrorCode } from './errorCodes';

export const errorConfig: ErrorMapping = {
  [ErrorCode.VALIDATION_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.AUTHENTICATION_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'REDIRECT'
  },
  [ErrorCode.NOT_FOUND_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'REDIRECT'
  },
  [ErrorCode.EXTERNAL_SERVICE_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.RATE_LIMIT_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.SERVER_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'CONTACT_SUPPORT'
  },
  [ErrorCode.CREDIT_INSUFFICIENT]: {
    severity: ErrorSeverity.HIGH,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'REDIRECT'
  },
  [ErrorCode.CREDIT_PARTIALLY_SUFFICIENT]: {
    severity: ErrorSeverity.HIGH,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'REDIRECT'
  },
  [ErrorCode.CHAT_PERMISSION_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: false,
    userActionRequired: false,
    clearanceAction: 'REDIRECT'
  },
  [ErrorCode.INVALID_MODEL]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.MESSAGE_TOO_LONG]: {
    severity: ErrorSeverity.HIGH,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.CONTEXT_LIMIT_EXCEEDED]: {
    severity: ErrorSeverity.HIGH,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.MODERATED_CONTENT_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'REDIRECT'
  },
  [ErrorCode.SERVICE_UNAVAILABLE_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.TIMEOUT_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.OPENROUTER_ACCOUNT_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'CONTACT_SUPPORT'
  },
  [ErrorCode.OPENROUTER_RATE_LIMIT_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.OPENROUTER_TIMEOUT_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.OPENROUTER_API_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'CONTACT_SUPPORT'
  },
  [ErrorCode.OPENROUTER_UPSTREAM_ERROR]: {
    severity: ErrorSeverity.HIGH,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'CONTACT_SUPPORT'
  },
  [ErrorCode.TITLE_GENERATION_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.GENERATION_DETAILS_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.FILE_SIZE_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.FILE_TYPE_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: false,
    userActionRequired: true,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.FILE_UPLOAD_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.FILE_PARSING_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
  [ErrorCode.FILE_NETWORK_ERROR]: {
    severity: ErrorSeverity.MEDIUM,
    retryable: true,
    userActionRequired: false,
    clearanceAction: 'RETRY'
  },
};