export const CONFIG = {
    API_URL: process.env.REACT_APP_ENV === 'prod' 
      ? process.env.REACT_APP_API_URL 
      : process.env.REACT_APP_API_URL_DEV,
    SOCKET_URL: process.env.REACT_APP_ENV === 'prod'
      ? process.env.REACT_APP_SOCKET_URL
      : process.env.REACT_APP_SOCKET_URL_DEV,
    SOCKET_IO_PING_TIMEOUT: process.env.REACT_APP_ENV === 'prod'
      ? process.env.REACT_APP_SOCKET_IO_PING_TIMEOUT
      : process.env.REACT_APP_SOCKET_IO_PING_TIMEOUT_DEV,
    SOCKET_IO_PING_INTERVAL: process.env.REACT_APP_ENV === 'prod'
      ? process.env.REACT_APP_SOCKET_IO_PING_INTERVAL
      : process.env.REACT_APP_SOCKET_IO_PING_INTERVAL_DEV,
    SOCKET: {
      HEARTBEAT_INTERVAL: 30000, // 30 seconds
      HEARTBEAT_TIMEOUT: 10000, // 10 seconds
      RECONNECTION: {
        ATTEMPTS: Infinity,
        DELAY: 1000,
        DELAY_MAX: 5000,
        RANDOMIZATION_FACTOR: 0.5,
      },
    },
    SUPABASE_FORGOT_PASSWORD_REDIRECT_URL: process.env.REACT_APP_SUPABASE_FORGOT_PASSWORD_REDIRECT_URL,
  };

  console.log("CONFIG.SOCKET_URL", CONFIG.SOCKET_URL);
