import React, { Suspense, lazy } from 'react';
import { useLocation } from 'react-router-dom';
import SettingsTransition from './components/Transition';
import { SettingsContent } from './components/SettingsComponents';
import LoadingIndicator from '../common/LoadingIndicator';

const components = {
  '/settings/profile': lazy(() => import('./pages/Profile')),
  '/settings/account': lazy(() => import('./pages/Account')),
  '/settings/notifications': lazy(() => import('./pages/Notifications')),
  '/settings/usage': lazy(() => import('./pages/Usage')),
  '/settings/billing': lazy(() => import('./pages/Billing')),
  '/settings/activity': lazy(() => import('./pages/Activity')),
  '/settings/transfer': lazy(() => import('./pages/Transfer')),
  '/settings/faqs': lazy(() => import('./pages/FAQ')),
  '/settings/documentation': lazy(() => import('./pages/Documentation')),
  '/settings/support': lazy(() => import('./pages/Support')),
  '/settings': lazy(() => import('./pages/Profile')),
};

const SettingsRouter: React.FC = () => {
  const location = useLocation();
  const Component = components[location.pathname as keyof typeof components] || components['/settings'];

  return (
    <SettingsContent>
      <SettingsTransition>
        <Suspense fallback={
          <LoadingIndicator variant="pulse" size="small"/>
        }>
          <Component />
        </Suspense>
      </SettingsTransition>
    </SettingsContent>
  );
};

export default React.memo(SettingsRouter);