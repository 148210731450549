export enum MessageSenderType {
    USER = 'user',
    SYSTEM = 'system',
    INTERNAL = 'internal',
    ASSISTANT = 'assistant',
    OTHER = 'other'
} 

export enum MessageContentType {
    TEXT = 'text',
    AUDIO = 'audio',
    FILE = 'file',
    IMAGE = 'image',
    SEARCH = 'search',
    VIDEO = 'video'
} 

export interface Message {
  _id: string;
  senderId: string;
  senderType: MessageSenderType;
  toModelId: string;
  contentType: MessageContentType;
  content: string;
  chatId: string;

  // Media Content
  mediaContent?: {
    storageUrl?: string;
    cdnUrl?: string;
    base64?: string;
    mimeType?: string;
    size?: number;
    transcription?: string;
  };
  attachments?: string[];

  // Message Metadata
  metadata?: Record<string, any>;
  isUser: boolean;
  voiceInput?: boolean;
  projectKnowledgeEnabled?: boolean;

  // Message Status
  isEdited?: boolean;
  isDeleted?: boolean;
  isFailed?: boolean;
  isComplete?: boolean;
  generationDetailsAvailable?: boolean;

  // Generation Details
  generationId?: string;
  calculatedTokenCount: number;
  tokensPrompt?: number;
  tokensCompletion?: number;
  totalMessageCost?: number;

  createdAt?: string;
  updatedAt?: string;
} 